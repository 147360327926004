import React, {Component} from 'react';
import {Link , Redirect} from 'react-router-dom';
import $ from 'jquery';
import {api_url} from '../Config';
import Header from './Header';
import swal from 'sweetalert';
import site_logo from '../assets/images/loader-logo.png';
import delete_account from '../assets/images/delete-account.png';
import Footer from './Footer';
class CustomerHeader extends Component {
    constructor(){
        super();
        this.state = {
            redirectToReferrer: false,
            base_url:api_url.base_url,
            site_logo:'',
        };
        this.Logout = this.Logout.bind(this);
    }
    componentDidMount(){
        var api_rul_info = this.state.base_url+'api.php?type=get_site_info';
        fetch(api_rul_info)
        .then(res=>res.json())
        .then(result=>{
            if (result.response==1) {
                this.setState({
                    site_logo:result.site_logo
                })

            }
        })


        $(".delete_account img").click(function(){
          $(".sidebar_box,.overlay_body").fadeIn();
        });

        $(".overlay_body,.close_btn_icon").click(function(){
          $(".sidebar_box,.overlay_body").fadeOut();
        });


    }
    Logout(){
        localStorage.removeItem('customer_id');
        this.setState({redirectToReferrer:true})
    }
    render(){
        if(this.state.redirectToReferrer)
        {
            return(<Redirect to='/from-me-shipment' />)
        }
        return(


           

            <div className="row top_head co-one" id="order_listing">
                <div className="col-sm-2 back_btn delete_account">
                   <img src={delete_account} alt=""/>
                </div>
                <div className="col-sm-8 page_title">
                    <h4>{this.props.title}</h4>
                </div>
                <div className="col-sm-2 right_logo">
                     <a className="logout-btn" style={{cursor:'pointer'}} onClick={()=>this.Logout()}>
                        <svg width="25px" height="25px"  viewBox="0 0 24 24"><path d="M5 3h6a3 3 0 0 1 3 3v4h-1V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v13a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-4h1v4a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3zm3 9h11.25L16 8.75l.664-.75l4.5 4.5l-4.5 4.5l-.664-.75L19.25 13H8v-1z" fill="#fff"/></svg>
                    </a>
                </div>

                 <div className="overlay_body"></div>
            <section className="sidebar_box">
              <div className="menu_head">
                <div className="close_btn_icon">
                  <i className="fa fa-close"></i>
                </div>
                 <img src={site_logo} alt=""/>
              </div>
              <div className="navbar_items">
                <ul>
                  <li>
                    <Link to="/">Dashboard</Link>
                   <svg aria-hidden="true" focusable="false" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M16 8.414l-4.5-4.5L4.414 11H6v8h3v-6h5v6h3v-8h1.586L17 9.414V6h-1v2.414zM2 12l9.5-9.5L15 6V5h3v4l3 3h-3v7.998h-5v-6h-3v6H5V12H2z" fill="#fff"></path></svg>
                  </li>
                  <li>
                   <Link to="/booking">Booking </Link>
                   <svg viewBox="0 0 24 24"><path d="M11.5 7a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5zm0 1a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zm-4.7 4.357l4.7 7.73l4.7-7.73a5.5 5.5 0 1 0-9.4 0zm10.254.52L11.5 22.012l-5.554-9.135a6.5 6.5 0 1 1 11.11 0h-.002z" fill="#fff"></path></svg>
                  </li>
                  
                  <li>
                    <Link to="/orders">Order List</Link>
                    <svg viewBox="0 0 24 24"><path d="M7 2h1a1 1 0 0 1 1 1v1h5V3a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a3 3 0 0 1 3 3v11a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3V3a1 1 0 0 1 1-1zm8 2h1V3h-1v1zM8 4V3H7v1h1zM6 5a2 2 0 0 0-2 2v1h15V7a2 2 0 0 0-2-2H6zM4 18a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2V9H4v9zm8-5h5v5h-5v-5zm1 1v3h3v-3h-3z" fill="#fff"></path></svg>
                  </li>
                  <li>
                    <Link to="/delete-account">Delete My Account</Link>
                   <img src={delete_account} alt=""/>
                  </li>
                  
                  <li>
                     <a   style={{cursor:'pointer'}} className="logout-btn" style={{color:'#fff'}} onClick={()=>this.Logout()}>Logout</a>
                    <svg width="25px" height="25px" viewBox="0 0 24 24"><path d="M5 3h6a3 3 0 0 1 3 3v4h-1V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v13a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-4h1v4a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3zm3 9h11.25L16 8.75l.664-.75l4.5 4.5l-4.5 4.5l-.664-.75L19.25 13H8v-1z" fill="#fff"></path></svg>
                  </li>
                  
                </ul>
              </div>
            </section>
            </div>








        )
    }
}
export default CustomerHeader;
