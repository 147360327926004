import React, {Component} from 'react';
import $ from 'jquery';
import {api_url} from '../Config';
import swal from 'sweetalert';
import Footer from './Footer';
import CommonHeader from '../component/CommonHeader';
class ContactUs extends Component {
    constructor(){
    super();
    this.state = {
      base_url:api_url.base_url,
        redirectToReferrer: true,
        name:'',
        phone_no:'',
        nameErr:'',
        mobileErr:'',
        message:'',
        messageErr:'',

    };

    this.ContactUs = this.ContactUs.bind(this);
    this.onChange = this.onChange.bind(this);
}
componentDidMount(){
  $("#table_btn_menu").click(function(){
    $(".main_wrapper_table").toggle();
  });

  $("#table_btn_menu2").click(function(){
    $(".main_wrapper_table2").toggle();
  });

  $("#table_btn_menu3").click(function(){
    $(".main_wrapper_table3").toggle();
  });

  $("#table_btn_menu4").click(function(){
    $(".main_wrapper_table4").toggle();
  });
}
// componentWillMount() {
//  if(sessionStorage.getItem("userData")){
//      this.getUserData();
//  }
//  else{
//      this.setState({redirectToReferrer: true});
//  }
// }
// getUserData() {
//  JSON.parse(sessionStorage.getItem("userData"));

// }

validation(){
  if(this.state.name==="" && this.state.phone_no===""  && this.state.message===''){
    this.setState(
    {
      nameErr:"Name is required",
      mobileErr:"Mobile is required",
      messageErr:'Please enter your message',
    })
  }else if(this.state.name===""){
    this.setState(
    {
      nameErr:"Name is required",
      mobileErr:"",
      messageErr:'',
    })
  }else if(this.state.phone_no==="")
  {
    this.setState(
    {
      nameErr:"",
      mobileErr:"Mobile is required",
      messageErr:'',
    })
  }
  else if(this.state.message==="")
  {
    this.setState(
    {
      nameErr:"",
      mobileErr:"",
      messageErr:'Please enter your message',
    })
  }
  else if(this.state.message.length < 10)
  {
    this.setState(
    {
      nameErr:"",
      mobileErr:"",
      messageErr:'Message must contain at least 10 characters',
    })
  }
  else{
    return true
  }
}

ContactUs(e) {
    e.preventDefault();
    if(this.validation() ){
      this.setState({
        mobileErr:'',
        nameErr:'',
      })
      this.setState({loading:true})
      const sendData = {
          order_id:this.state.order_id,
          name:this.state.name,
          phone_no:this.state.phone_no,
          message:this.state.message
      }
      const trackURL = this.state.base_url+'api.php?type=contact_us';
      fetch(trackURL,
      {
          method: 'POST',
          headers:
          {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
          },
          body:JSON.stringify(sendData)
      })
      .then((response) => response.json())
      .then(result=>{
       if(result.response===1)
       {
         swal('Message Sent Successfully');
         this.setState({
            mobileErr:'',
            nameErr:'',
            name:'',
            phone_no:'',
            message:'',
            messageErr:'',
          })
       }
       this.setState({loading:false});
      })
      .catch((error) => {
          this.setState({loading:false});
      });
    }
}

onChange(e){
   this.setState({[e.target.name]:e.target.value});
}
render(){
const lang =JSON.parse(localStorage.getItem("lang"));
return(
<React.Fragment>


<div className="main_body register_main track_shipment_page" id="desktop_view">
    <div className="login_screen">
      <CommonHeader />
      <form onSubmit={this.ContactUs}>
      <div className="track_shipments pb-2 contact_page">
          <p>{lang.let_us_know}</p>

            <div className="form_box get_quote ">
          <input type="text" placeholder={lang.enter_your_name} name="name" value={this.state.name} onChange={this.onChange} />
            <svg className="search_box" viewBox="0 0 24 24"><path d="M11.5 14c4.142 0 7.5 1.567 7.5 3.5V20H4v-2.5c0-1.933 3.358-3.5 7.5-3.5zm6.5 3.5c0-1.38-2.91-2.5-6.5-2.5S5 16.12 5 17.5V19h13v-1.5zM11.5 5a3.5 3.5 0 1 1 0 7a3.5 3.5 0 0 1 0-7zm0 1a2.5 2.5 0 1 0 0 5a2.5 2.5 0 0 0 0-5z" fill="#626262"></path></svg>
            <span>{this.state.nameErr}</span>
        </div>
        <div className="form_box get_quote  margin_none">
          <input type="text" placeholder={lang.enter_your_phone} name="phone_no" value={this.state.phone_no} onChange={this.onChange}  />
          <svg className="search_box" viewBox="0 0 24 24"><path d="M19.5 22c.827 0 1.5-.673 1.5-1.5V17c0-.827-.673-1.5-1.5-1.5c-1.17 0-2.32-.184-3.42-.547a1.523 1.523 0 0 0-1.523.363l-1.44 1.44a14.655 14.655 0 0 1-5.885-5.883L8.66 9.436c.412-.382.56-.963.384-1.522A10.872 10.872 0 0 1 8.5 4.5C8.5 3.673 7.827 3 7 3H3.5C2.673 3 2 3.673 2 4.5C2 14.15 9.85 22 19.5 22zM3.5 4H7a.5.5 0 0 1 .5.5c0 1.277.2 2.531.593 3.72a.473.473 0 0 1-.127.497L6.01 10.683c1.637 3.228 4.055 5.646 7.298 7.297l1.949-1.95a.516.516 0 0 1 .516-.126c1.196.396 2.45.596 3.727.596c.275 0 .5.225.5.5v3.5c0 .275-.225.5-.5.5C10.402 21 3 13.598 3 4.5a.5.5 0 0 1 .5-.5z" fill="#626262"/></svg>
          <span>{this.state.mobileErr}</span>
        </div>

        <div className="form_box get_quote ">
          <textarea type="text" className="textarea_box" placeholder="Your Message Here" name="message" value={this.state.message} onChange={this.onChange}></textarea>
          <span>{this.state.messageErr}</span>
        </div>



    </div>
   <div id="contact_us_box">
    <div className="shipment_box m-0 pb-3" id="rate_transit">
      <input type="submit" className="contact_btn login_btn_ shipment_btns mb-0 ml-2 mt-0 mr-2 w-75" value="Contact Us" />

    </div>
   </div>
     </form>

  </div>
</div>




<Footer />
</React.Fragment>
    )
}
}
export default ContactUs;
