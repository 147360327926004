import React, {Component} from 'react';
import { Redirect, Link} from 'react-router-dom';
import {api_url} from '../Config';
import swal from 'sweetalert';
import $ from 'jquery';
import Footer from './Footer';
import CommonHeader from '../component/CommonHeader';
import checkbox from '../assets/images/checkbox-icon.png';
class DeleteAccount extends Component {
  constructor(){
    super();
    this.state = {
        redirectToReferrer: false,
        base_url:api_url.base_url,
        email:'',
        password:'',
    };
    this.save_record = this.save_record.bind(this);
    this.onChange = this.onChange.bind(this);
    this.login = this.login.bind(this);
}
componentDidMount(){
       

        $(".connect_btn").click(function(){
          $(".fix_logo_f").hide();
          $(".alert_mes").show();
        });

     
    }
// componentWillMount() {
//   if(sessionStorage.getItem("userData")){
//     this.getUserData();
//   }
//   else{
//     this.setState({redirectToReferrer: true});
//   }
// }
// getUserData() {
//   JSON.parse(sessionStorage.getItem("userData"));

// }

login()
{
  if(this.state.email ===''|| this.state.password==='')
  {
    alert('Email and Password required.')
  }else{
            let signupData = {
            email:this.state.email,
            'password':this.state.password
        }
        const addUrl = this.state.base_url+'consignee_api.php?type=consignee_login';
        fetch(addUrl,
        {

            method: 'POST',
            headers:
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify(signupData)
        })
        .then((response) => response.json()
            .then((result) => {
               if(result.consignee_id > 0)
               {
                 localStorage.setItem('consignee_id',result.consignee_id);
                 this.save_record(result.consignee_id);
               }else{
                 swal('Invalid Details.')
               }
        }))
        .catch((error) => {

            });
  }


}
save_record()
{
  let addData = {
        track_no:localStorage.getItem('track_no'),
        'consignee_id':localStorage.getItem('consignee_id')
    }
    const addUrl = this.state.base_url+'consignee_api.php?type=save_record';
    fetch(addUrl,
    {

        method: 'POST',
        headers:
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body:JSON.stringify(addData)
    })
    .then((response) => response.json()
        .then((result) => {
            if(result.response===1){
              this.setState({
                redirectToReferrer:true,
              })
            }
    }))
    .catch((error) => {

        });
}
onChange(e){
   this.setState({[e.target.name]:e.target.value});
}
render(){
  const lang =JSON.parse(localStorage.getItem("lang"));
if(this.state.redirectToReferrer)
  {
    return(<Redirect to='track-listing' />)
  }
return(

<React.Fragment>
  <body id="page-name">
<CommonHeader />
<div className="main_body body_img_bg">
<div className="login_screen fix_screen" id="register_screen" style={{padding: '63px 0 0 !important'}}>
    <div className="row" id="del_account">
      <div className="col-lg-12 fix_logo_f ">
        <h3>Are you sure you want to delete your account?</h3>
        <ul>
        <li><Link to="#" className="connect_btn">Yes</Link></li>
        <li><Link to="/" className="home__btn">No</Link></li>
        </ul>
      </div>

      <div className="alert_mes">
      
       <img src={checkbox} alt=""/>
      <p>Your request to delete your account has been submitted successfully!</p>
      </div>
    </div>

    

  </div>

</div>

<Footer />

</body>
</React.Fragment>
  )
}
}
export default DeleteAccount;
